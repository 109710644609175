/*=========================================================================================
  File Name: moduleTodoState.js
  Description: Todo Module State
  ----------------------------------------------------------------------------------------
  Item Name: Administrative Board
  Author: Enkompass
  Author URL: http://www.enkompass.net
==========================================================================================*/


export default {
  tasks: [],
  lawFirmData: [],
  currentData: [],
  menuType: 'accounts',
  items: [],
  taskTags: [],
  todoSearchQuery: '',
  todoFilter: null,
  metricsData: []
}
