/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Administrative Board
  Author: Enkompass
  Author URL: http://www.enkompass.net
==========================================================================================*/

export default {
  UPDATE_LAW_FIRM_DATA (state, firms) {
    state.lawFirmData = firms
  },
  UPDATE_CURRENT_DATA (state, data) {
    state.currentData = data
  },
  UPDATE_DATA_TYPE (state, dataType) {
    state.menuType = dataType
  },
  UPDATE_TABLE_MENU_ITEMS (state, items) {
    console.log('Updating to -> : ', items);
    state.items.menu = items
  },

  SET_TODO_SEARCH_QUERY (state, query) {
    state.todoSearchQuery = query
  },
  UPDATE_TODO_FILTER (state, filter) {
    state.todoFilter = filter
  },

  // API
  SET_TASKS (state, tasks) {
    state.tasks = tasks
  },
  SET_TAGS (state, tags) {
    state.taskTags = tags
  },
  SET_FIRM_DATA (state, data) {
    state.firmData = data
  },
  ADD_TASK (state, task) {
    state.tasks.unshift(task)
  },
  UPDATE_TASK (state, task) {
    const taskIndex = state.tasks.findIndex((t) => t.id === task.id)
    Object.assign(state.tasks[taskIndex], task)
  },
  SET_METRICS(state, data) {
    state.metricsData = data
  }
}
