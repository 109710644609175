/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Legal Board Services
  Author: Enkompass
  Author URL: https://www.enkompass.net
==========================================================================================*/

import axios from '@/axios.js'

export default {
  fetchMetrics ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('metrics')
        .then((response) => {
          commit('SET_METRICS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteData ({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = ''

      //Delete only available for firms
      // if(this.state.todo.menuType == 'accounts'){
      //   url = this.state.todo.menuType + '/' + data.uuid
      // }
      //
      // if(this.state.todo.menuType == 'cases'){
      //   url = 'accounts/' + data.accountId + '/cases/' + data.uuid
      // }
      //
      // if(this.state.todo.menuType == 'users'){
      //   url = 'accounts/' + data.accountId + '/users'
      // }

      url = data.menuType + '/' + data.uuid

      axios.delete(url)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error.response.data.message.message)
        });

    })
  },

  addData ({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = ''

      url = this.state.todo.menuType

      if(data.addType === 'case'){
        url = 'accounts/' + data.firmId + '/cases'
      }

      if(data.addType === 'user'){
        url = 'accounts/' + data.firmId + '/users'
      }

      data.createdOn = new Date().toISOString();
      data.updatedOn = new Date().toISOString();

      axios.post(url, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error.response.data.message.message)
        });
    })
  },
  fetchFirmData ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/accounts/'+payload.uuid+'/'+payload.display_type)
        .then((response) => {
          commit('SET_FIRM_DATA', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateData ({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = ''
      if(data.activeType === 'accounts'){
        url = data.activeType
      }

      if(data.activeType === 'cases'){
        url = 'accounts/' + data.data.accountId + '/cases/' + data.data.uuid
      }

      if(data.activeType === 'users'){
        url = 'accounts/' + data.data.accountId + '/users'
      }

      axios.put(url, data.data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setTodoSearchQuery ({ commit }, query) {
    commit('SET_TODO_SEARCH_QUERY', query)
  },
  fetchTasks ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/apps/todo/tasks', { params: {filter: payload.filter} })
        .then((response) => {
          commit('SET_TASKS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFirms ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('accounts', { params: {filter: payload.filter} })
        .then((response) => {
          commit('UPDATE_LAW_FIRM_DATA', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchData ({ commit }, item) {
    commit('UPDATE_DATA_TYPE', item)
    return new Promise((resolve, reject) => {
      axios.get(item)
        .then((response) => {
          if (item === 'accounts') commit('UPDATE_LAW_FIRM_DATA', response.data)
          commit('UPDATE_CURRENT_DATA', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchTags ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/apps/todo/tags')
        .then((response) => {
          commit('SET_TAGS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addTask ({ commit }, task) {
    return new Promise((resolve, reject) => {
      axios.post('/api/apps/todo/tasks/', {task})
        .then((response) => {
          commit('ADD_TASK', Object.assign(task, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateTask ({ commit }, task) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/apps/todo/task/${task.id}`, {task})
        .then((response) => {
          commit('UPDATE_TASK', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchSlicerDocuments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/accounts/'+payload.accountId +'/cases/'+payload.caseId + '/slicer/documents')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchSlicerDocument({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/accounts/'+payload.accountId +'/cases/'+payload.caseId + '/documents/' + payload.uuid)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  saveSlicerDocument({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/accounts/'+payload.accountId +'/cases/'+payload.caseId + '/documents/' + payload.uuid + "/slicer/save",
        {slicingInfo: payload.slicingInfo}).then((response) => {
          resolve(response.data)
        }).catch((error) => { reject(error) })
    })
  },

  publishSlicerDocument({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/accounts/'+payload.accountId +'/cases/'+payload.caseId + '/documents/' + payload.uuid +
        "/slicer/publish", {slicingInfo: payload.slicingInfo}).then((response) => {
          resolve(response.data)
        }).catch((error) => { reject(error) })
    })
  },

  fetchCaseDocumentList({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/accounts/'+payload.accountId +'/cases/'+payload.caseId + '/documents').then((response) => {
          resolve(response.data)
        }).catch((error) => { reject(error) })
    })
  },

  fetchCaseDocumentKeyDataReport({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/accounts/'+payload.accountId +'/cases/'+payload.caseId + '/documents/' + payload.documentId + '/keydata').then((response) => {
          resolve(response.data)
        }).catch((error) => { reject(error) })
    })
  }
}
