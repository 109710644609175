<template>
  <div id="data-model-container" class="vs-con-loading__container">
    <div id="data-list-list-view" v-if="itemData" class="data-list-container">
      <div slot="header" id="addnewcaseBtn" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <vx-tooltip text="Go Back" position="bottom" class="mr-4">
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              @click.stop="goBack()" 
            />
          </vx-tooltip>
        </div>
      </div>
      <vs-table stripe :data="itemData">
        <template slot="thead" v-if="menuItems[menuType]">
          <vs-th :sort-key="menu.sortKey" v-for="menu in menuItems[menuType]" :key="menu.label">{{menu.label}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody v-if="data">

          <vs-tr class="cursor-pointer" :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td>
              <p class="product-name font-medium">{{ tr.fileName }}</p>
            </vs-td>

            <vs-td>
              <p class="encryption-key">{{ formatDate(tr.createdOn) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.isReadyForSlicing }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.isSliced }}</p>
            </vs-td>

            <vs-td>
              <vx-tooltip text="Edit" position="left">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                              @click.stop="editItem(tr)" />
              </vx-tooltip>
            </vs-td>

          </vs-tr>
          </tbody>
        </template>

      </vs-table>
    </div>
  </div>
</template>

<script>
  import moduleTodo from '@/store/todo/moduleTodo.js'

  export default {
    components: {},
    data () {
      return {
        itemData: [],
        menuType: 'documents',
        menuItems: {
          documents: [
            {label: "File Name", sortKey: "name"}, 
            {label: "Created", sortKey: "createdOn"}, 
            {label: "Ready For Slicer", sortKey: "readyForSlicer"}, 
            {label: "Sliced", sortKey: "isSliced"}, 
            {label: "Actions", sortKey: "name"}, 
          ]
        },
        accountId: this.$route.params.accountId,
        caseId: this.$route.params.caseId,
      }
    },
    created() {
      if (!this.$store.hasModule("todo")) {
        this.$store.registerModule('todo', moduleTodo)
      }
    },
    mounted () {
      this.refreshData()
    },
    computed: {
      title () {
        return ('View Documents ready for PDF Slicer')
      }
    },
    methods: {
      refreshData() {
        this.$vs.loading({
          container: '#data-model-container',
        })
        this.$store.dispatch('todo/fetchSlicerDocuments', {
          accountId: this.accountId,
          caseId: this.caseId
        }).then(response => {
          this.itemData = response.items
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        }).catch(error => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
      },
      goBack() {
        this.$router.push({name: "account-cases", params: {accountId: this.accountId}});
      },
      formatDate(value) {
        if (value && this.moment)
          return this.moment(value).format('DD/MM/YYYY')
        else return ''
      },
      editItem(item){
        this.$router.push({
          name: 'case-document-pdfslicer', 
          params: {
            accountId: item.accountId, 
            caseId: item.caseId, 
            documentId: item.uuid
          },
          query: {
            source: "case-document-readyforslicing"
          }
        })
      },
    },
    beforeDestroy () {
      this.$store.unregisterModule('todo')
    },
  }
</script>
<style lang="scss">
  .con-vs-tooltip{
    width: 20px;
    margin: 0;
    padding: 0;
    display: inline;
    cursor: pointer;
  }
  #addnewcaseBtn{
    width: 50%;
    float: left;
    margin: auto 1rem;
  }

  #data-list-list-view .vs-con-table .vs-table--header {
    margin: 1rem;
    font-size: 12px;
  }

  .vs-table--search {
    max-width: 100%;
    width: 100%;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 0.65rem 2rem;
    width: 100%;
    margin-right: 18px;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input + i,
  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input:focus + i {
    left: 0.5rem;
  }

  .vs-table--td {
    padding: 10px 20px !important;
  }


</style>
